<template>
  <moe-page title="APP广告详情">
    <moe-form v-if="appAdvertData.id" :showBack="false" :showClose="true">
      <el-form-item label="广告位置">
        {{ $moe_format.getAppAdvertType(appAdvertData.type) }}
      </el-form-item>
      <el-form-item label="媒体类型">
        {{ appAdvertData.urlType === '1' ? '图片' : '' }}
        {{ appAdvertData.urlType === '2' ? '视频' : '' }}
      </el-form-item>
      <el-form-item label="广告图片" v-if="appAdvertData.urlType === '1'">
        <moe-image :src="appAdvertData.coverUrl" width="100px" height="100px"></moe-image>
      </el-form-item>
      <el-form-item label="广告视频" v-else-if="appAdvertData.urlType === '2'">
        <video controls width="300px" height="300px" :src="appAdvertData.coverUrl"></video>
      </el-form-item>
      <el-form-item label="跳转类型">
        {{ $moe_format.getAdvertForm(appAdvertData.form) }}
      </el-form-item>
      <template v-if="appAdvertData.form === 1">
        <el-form-item label="站外跳转链接">
          {{ appAdvertData.link }}
        </el-form-item>
      </template>
      <template v-if="appAdvertData.form === 2">
        <el-form-item label="内部跳转地址">
          {{ $moe_format.getAdvertLink(appAdvertData.link) }}
        </el-form-item>
        <el-form-item v-if="$moe_data.navigateToIsValidator.includes(appAdvertData.link)" label="编号">
          {{ appAdvertData.activityId }}
        </el-form-item>
      </template>
      <el-form-item label="排序">
        {{ appAdvertData.sort }}
      </el-form-item>
      <el-form-item label="备注">
        {{ appAdvertData.remark }}
      </el-form-item>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      appAdvertData: {},
    }
  },
  mounted() {
    this.$moe_api.ADVERT_API.appAdvertDetails({ id: this.$route.query.id }).then((data) => {
      if (data.code === 200) {
        let { form, link } = data.result;
        let newLink = '', activityId = '';
        if (form === 2 && link.indexOf('?') > -1 && this.$moe_data.navigateToIsValidator.includes(link.split('?')[0])) { // N元任选活动
          newLink = link.split('?')[0]
          activityId = this.$moe_formatter.getUrlParams(link).id;
        } else {
          newLink = link
        }
        this.appAdvertData = {
          ...data.result,
          link: newLink,
          activityId
        };
      } else {
        this.$moe_msg.error(data.message);
      }
    })
  }
}
</script>